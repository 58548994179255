var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sameday-layout-parent",class:{
        'sameday-layout-width-x-small': _vm.pWidth === 'x-small',
        'sameday-layout-width-small': _vm.pWidth === 'small',
        'sameday-layout-width-medium': _vm.pWidth === 'medium',
        'sameday-layout-width-large': _vm.pWidth === 'large',
        'sameday-layout-width-horizon': _vm.pWidth === 'horizon',

        'sameday-layout-position-center': _vm.pPosition === 'center',
        'sameday-layout-position-left': _vm.pPosition === 'left',
        'sameday-layout-position-right': _vm.pPosition === 'right',

        'sameday-layout-padding-none': _vm.pPadding === 'none',
        'sameday-layout-padding-small': _vm.pPadding === 'small',
        'sameday-layout-padding-large': _vm.pPadding === 'large',

        'sameday-layout-mobile-padding-none': _vm.pMobilePadding === 'none',
        'sameday-layout-mobile-padding-small': _vm.pMobilePadding === 'small',
        'sameday-layout-mobile-padding-large': _vm.pMobilePadding === 'large',
    },attrs:{"id":_vm.pId}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }