//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import $ from 'bucks-js'
    import NavigationBar from '../layoutComponents/NavigationBar/NavigationBar'
    import PortalSideBar from '../layoutComponents/Portal/sideBar/PortalSidebar'
    import PortalContentFrame from '../layoutComponents/Portal/PortalContentFrame'

    export default {
        name: 'PortalLayout',
        components: { NavigationBar, PortalSideBar, PortalContentFrame },
        data: () => ({
            sideBarOpen: false
        }),
        computed: {
            authenticationPage () {
                return (this.$route.path.indexOf('login') > 0)
					|| (this.$route.path.indexOf('auth') > 0)
					|| (this.$route.path.indexOf('sign-up') > 0)
            },
        },
        mounted () {
            if ($.ui.isClient()) {
                console.log('%cWarning!', 'color: red; font-size: 30px')
                console.log('%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a Sameday Health feature or "hack" someone\'s account, it is a scam and will give them access to your Sameday Health account!', 'font-size: 20px')
            }
        },
        watch: {
            /**
             * Closes side bar on mobile on route change.
             */
            '$route' () {
                this.sideBarOpen = false
            }
        },
    }
