//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SamedayLayout from '../../components/UserInterface/layout/SamedayLayout'

export default {
    name: 'PortalContentFrame',
    components: { SamedayLayout },
}
