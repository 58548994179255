//
//
//
//
//
//
//
//
//
//
//
//

import NavigationBar from '@/layoutComponents/NavigationBar/NavigationBar'
import ErrorNotFoundContainer from '@/components/Error/ErrorNotFoundContainer'

export default {
    name: 'error',
    props: ['error'],
    layout: 'error',
    components: { NavigationBar, ErrorNotFoundContainer },
    mounted () {
        const path = this.$route.fullPath
        const paths = [
            '/virginia--alexandria--309-s-washington-st/vaccination',
            '/virginia--tysons-corner/vaccination',
        ]
        if (paths.includes(path)) {
            this.$router.push({ path: '/booking' + path })
        }
    },
}
