//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BookingStructureProgressBar',
    props: {
        currentProgress: { type: Number, required: true },
        hasMargin: { type: Boolean, default: true },
        fullWidth: { type: Boolean, default: false }
    },
}
