//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SamedayPhoto from './SamedayPhoto.vue'

export default {
    name: 'SamedayBackgroundPhoto',
    components: { SamedayPhoto },
    props: {
        photoUrl: { type: String, required: true },
        alternative: { default: null },

        // sizing
        wMobile: { type: Number, default: 600 },
        wTablet: { type: Number, default: 1000 },
        wDesktop: { type: Number, default: 1400 },

        // prevents click event
        prevent: { type: Boolean, default: false },
    },
    methods: {
        /**
         * Handles click event.
         * @param ev
         */
        click (ev) {
            let newEvent = ev
            // preventDefault destroys event
            if (this.prevent) ev.preventDefault()
            this.$emit('click', newEvent)
        },
    },
}
