//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'bucks-js'
import SamedayLayoutParent from './components/SamedayLayoutParent.vue'
import SamedayLayoutChild from './components/SamedayLayoutChild.vue'

const widthTypes = ['x-small', 'small', 'medium', 'large', 'horizon']
const positionTypes = ['center', 'left', 'right']
const paddingTypes = ['none', 'small', 'large']
const contentTypes = ['basic', 'grid']
const spacingTypes = ['none', 'medium']

export default {
    name: 'SamedayLayout',
    components: { SamedayLayoutParent, SamedayLayoutChild },
    props: {
        // ids for layout frames
        pId: { type: String, default: null },
        cId: { type: String, default: null },

        // class for layout frames
        cClass: { type: Object, default: null },

        // style for layout frames
        cStyle: { type: Object, default: null },

        pWidth: {
            validator: (value) => { return $.array.includes(widthTypes, value) },
            default: 'large',
        },
        pPosition: {
            validator: (value) => { return $.array.includes(positionTypes, value) },
            default: 'center',
        },
        cWidth: {
            validator: (value) => { return $.array.includes(widthTypes, value) },
            default: 'horizon',
        },
        cPosition: {
            validator: (value) => { return $.array.includes(positionTypes, value) },
            default: 'center',
        },

        // evaluates the padding of the parent container
        padding: {
            validator: (value) => { return $.array.includes(paddingTypes, value) },
            default: 'large',
        },
        mobilePadding: { default: null },

        // evaluates the content is grid or gallery or something else (not working with width: horizon)
        content: {
            validator: (value) => { return $.array.includes(contentTypes, value) },
            default: 'basic',
        },

        // spacing in content "grid" and "gallery"
        spacing: {
            validator: (value) => { return $.array.includes(spacingTypes, value) },
            default: 'medium'
        },

        // grid props
        gridDirection: { type: String, default: 'row' },
        gridJustifyContent: { type: String, default: 'flex-start' },
        gridAlignItems: { type: String, default: 'stretch' },

        // gallery props
        galleryItemsCount: { type: Number, default: 3 },
        galleryRowCount: { type: Number, default: 3 },
        galleryRowCountTablet: { default: null }, // number or null
        galleryRowCountMobile: { type: Number, default: 2 },
        galleryTabletScrollable: { type: Boolean, default: true }, // decides control on tablet
        galleryColor: { type: String, default: '#000000' },
    },
    computed: {
        /**
         * Computes mobile padding.
         * @return {*}
         */
        mobilePaddingComputed () {
            return this.mobilePadding ? this.mobilePadding : this.padding
        },
    },
}
