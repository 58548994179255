//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SamedayButton from '../UserInterface/button/SamedayButton'

export default {
    name: 'ErrorNotFoundContainer',
    components: { SamedayButton },
}
