//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'bucks-js'

export default {
    name: 'SamedayLayoutChild',
    components: {
        CoreGridLayout: () => import('./grid/CoreGridLayout.vue'),
    },
    props: {
        cId: { required: true },
        cClass: { required: true },
        cStyle: { required: true },

        cWidth: { type: String, required: true },
        cPosition: { type: String, required: true },

        pPadding: { type: String, required: true },
        content: { type: String, required: true },
        spacing: { type: String, required: true },

        // grid props
        gridDirection: { type: String, required: true },
        gridJustifyContent: { type: String, required: true },
        gridAlignItems: { type: String, required: true },

        // gallery props
        galleryItemsCount: { type: Number, required: true },
        galleryRowCountDesktop: { type: Number, required: true },
        galleryRowCountTablet: { required: true }, // number or null
        galleryRowCountMobile: { type: Number, required: true },
        galleryTabletScrollable: { type: Boolean, required: true },
        galleryColor: { type: String, required: true },
    },
    computed: {
        /**
         * Computes core layout child classes.
         */
        computedClasses () {
            const localClasses = {
                'sameday-layout-width-x-small': this.cWidth === 'x-small',
                'sameday-layout-width-small': this.cWidth === 'small',
                'sameday-layout-width-medium': this.cWidth === 'medium',
                'sameday-layout-width-large': this.cWidth === 'large',
                'sameday-layout-width-horizon': this.cWidth === 'horizon',

                'sameday-layout-position-center': this.cPosition === 'center',
                'sameday-layout-position-left': this.cPosition === 'left',
                'sameday-layout-position-right': this.cPosition === 'right',

                'sameday-layout-grid-medium-spacing': (this.content === 'grid' || this.content === 'gallery') && this.spacing === 'medium',
            }
            return $.object.is(this.cClass) ? Object.assign(localClasses, this.cClass) : localClasses
        },
    },
}
