//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import SamedayButton from '../../components/UserInterface/button/SamedayButton'
import RocketTracker from '@/services/RocketTracker'
import LoginIcon from '@/components/UserInterface/Svg/LoginIcon'
import LogoutIcon from '@/components/UserInterface/Svg/LogoutIcon'

export default {
    name: 'NavigationBar',
    components: { SamedayButton, LoginIcon, LogoutIcon },
    data: () => ({
       loading: false,
    }),
    props: {
        portal: { type: Boolean, default: false},    // if portal, display menu icon in mobile mode
        loginAvailable: { type: Boolean, default: true}
    },
    computed: {
        ...mapGetters({
            authenticated: 'user/authenticated',
        })
    },
    methods: {
        /**
         * Logout user and delete token cookie.
         */
        async logout () {
            this.loading = true
            await this.$store.dispatch('user/logout')
            await this.$store.commit('portal/logout')
            await this.$router.push({ path: '/login' })
            this.loading = false

            RocketTracker.trackEvent('Logout Tapped', { }, true)
        },
        /**
         * Redirect to login
         */
        login () {
            this.$router.push({ path: '/login' })
        },
    }
}
